<template>
  <div class="flex flex-col gap-2">
    <div
      v-for="(c, chauffeurIndex) in list"
      :key="`${c.id}${c.chauffeur_id}${chauffeurIndex}`"
      class="flex flex-row"
    >
      <div class="flex-1 flex flex-col">
        <div class="inline-flex gap-1 items-start">
          <button v-if="store.getters.hasMasterPermission" class="btn warning xs z-20" @click="onHandleCustomEdit(chauffeurIndex)">
            <i class="fas fa-edit text-sm"></i>
          </button>
          <SelectChauffeur
            v-model="list[chauffeurIndex].chauffeur_id"
            @update:modelValue="(chauffeur_id) => onSelectChauffeur(list[chauffeurIndex], chauffeur_id)"
            class="inline-block text-lg w-auto flex-1"
            placeholder="Chauffeur..."
            :active="store.getters.isKantoorPermission ? true : undefined"
            skip-mounted
            :disabled="disabled"
          />
          <SelectWagen
            v-if="false && store.getters.hasAdminPermission"
            active
            :disabled="disabled"
            v-model="list[chauffeurIndex].wagen_id"
            class="inline-block text-lg"
            placeholder="Wagen..."
          />
          <input
            v-if="store.getters.hasAdminPermission"
            v-model="list[chauffeurIndex].omzet"
            :disabled="disabled"
            @input="list[chauffeurIndex].omzet = $event?.target?.value || ''"
            type="number"
            class="input inline-block w-24 text-lg"
            :class="{ 'bg-red-500': omzetVerkeerd }"
            placeholder="Omzet"
          />
        </div>
        <div class="flex flex-row gap-4">
          <LabelShiftUren
            v-if="store.getters.hasKantoorPermission"
            compact
            alerts
            kalender
            :show-all="store.getters.hasAdminPermission"
            :start="list[chauffeurIndex]?.shift_start || null"
            :aangekomen="list[chauffeurIndex]?.shift_aangekomen || null"
            :klant_ingestapt="list[chauffeurIndex]?.shift_klant_ingestapt || null"
            :klant_afgezet="list[chauffeurIndex]?.shift_klant_afgezet || null"
            :einde="list[chauffeurIndex]?.shift_einde || null"
            :is-no-show="list[chauffeurIndex]?.shift_is_no_show || false"
            :wagen_id="list[chauffeurIndex]?.shift_wagen_id || null"
            :chiron_status="list[chauffeurIndex]?.shift_chiron_status || null"
            class="-mt-0.25"
          />
        </div>
      </div>
      <div class="col-span-2 flex flex-col items-end gap-1.5 mt-1.5">
        <div
          v-if="!disabled"
          class="inline-flex gap-1 items-end justify-end"
        >
          <button
            class="btn green w-6 h-6 inline-flex justify-center items-center"
            @click="addChauffeurs(1)"
          >
            +
          </button>
          <button
            class="btn error w-6 h-6 inline-flex justify-center items-center"
            @click="removeChauffeur(chauffeurIndex)"
          >
            -
          </button>
        </div>

        <button v-if="store.getters.hasMasterPermission && isChiron" class="btn pt-0.5 purple xs z-20" @click="onHandleChironReservatieSturen(chauffeurIndex)">
          Chiron
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  defineAsyncComponent,
  defineProps,
  defineEmits,
  onMounted,
  watch,
  inject,
  provide,
} from 'vue'
import { useVModel, useMounted } from '@vueuse/core'
import { useStore } from 'vuex'
import useModal from '@/hooks/useModal'
import useApi from '@/hooks/useApi'

const LabelShiftUren = defineAsyncComponent(() => import('@/components/UI/Labels/ShiftUren.vue'))

const SelectChauffeur = defineAsyncComponent(() =>
  import('@/components/UI/Select/Chauffeur.vue'),
)
const SelectWagen = defineAsyncComponent(() =>
  import('@/components/UI/Select/Wagen.vue'),
)

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  klant_id: {
    type: Number,
    default: null,
  },
  rit_id: {
    type: Number,
    required: true,
  },
  isRetour: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  omzetChauffeur: {
    type: Number,
    required: true,
  },
  omzetOnderaannemer: {
    type: Number,
    required: true,
  },
  omzetVerkeerd: {
    type: Boolean,
    default: false,
  },
  minimum: {
    type: Number,
    required: true,
  },
  blacklist: {
    type: Array,
    default() {
      return []
    },
  },
  datum: [Date, String, Number],
  isTeOntvangen: Boolean,
  isChiron: Boolean,
})

const [openFixedModal] = useModal({ fixed: true })
const api = useApi()
const store = useStore()
const isMounted = useMounted()
const emit = defineEmits(['touched', 'update:modelValue'])
const list = useVModel(props, 'modelValue', emit, {
  deep: true,
  passive: true,
})

onMounted(() => {
  const minimum = Math.max(1, props.minimum || 0)
  const aantal = minimum - (list.value?.length || 0)
  if (aantal > 0) {
    addChauffeurs(aantal)
    resetChauffeurOmzetten()
  }
  watch(() => (list.value || []).map(el => `${el.chauffeur_id}:${el.wagen_id}:${el.omzet}`).join('_'), (newKey, oldKey) => {
    if (newKey === oldKey) return
    emit('touched', true)
  }, { deep: true })
  watch(() => list.value?.length, () => resetChauffeurOmzetten())
})


const setItemOmzet = (item) => {
  const omzetCH = props.omzetChauffeur || 0
  const omzetOA = props.omzetOnderaannemer || 0

  const type = store.getters.chauffeur(item.chauffeur_id)?.type || 'chauffeur'
  const omzetDefault = type === 'onderaannemer' ? omzetOA : omzetCH
  const omzet = Math.floor(omzetDefault / list.value.length)
  if (item.omzet !== omzet) {
    item.omzet = omzet
  }
}

const resetChauffeurOmzetten = () => {
  list.value.forEach(setItemOmzet)
}

const addChauffeurs = (n = 1) => {
  if (!list.value?.length) {
    list.value = []
  }
  for (var i = 1; i <= n; i += 1) {
    const item = {
      chauffeur_id: null,
      is_bus: false,
      is_retour: props.isRetour,
      rit_id: props.rit_id,
      wagen_id: null,
    }
    list.value.push(item)
    setItemOmzet(item)
  }
}

const removeChauffeur = (chauffeurIndex) => {
  list.value.splice(chauffeurIndex, 1)
  if (!list.value.length) {
    addChauffeurs(1)
  }
}

const onSelectChauffeur = (item) => {
  if (!isMounted) return

  // Standaard wagen voor chauffeur invullen indien van toepassing
  if (!item.chauffeur_id) return
  const wagen_id = store.getters.chauffeur(item.chauffeur_id)?.wagen_id
  if (wagen_id) {
    item.wagen_id = wagen_id
  }
}

const afwezigheden = inject('afwezigheden')
const mapChauffeurAfwezigheden = (chauffeur) => {
  let found = false

  const list = afwezigheden?.value?.list || []
  if (list.length) {
    const afwezigheid = list.find((x) => x.user_id === chauffeur.value)
    if (props.datum && afwezigheid) {
      if (afwezigheid.start && afwezigheid.einde) {
        const start = new Date(afwezigheid.start).getTime()
        const d = new Date(props.datum).getTime()
        const einde = new Date(afwezigheid.einde).getTime()
        found = !!(d >= start && d <= einde)
      }
    }
  }

  if (found) {
    chauffeur.disabled = true
    chauffeur.label = `📆 ${chauffeur.label || ''}`
  }
}

const mapChauffeurBlacklist = (chauffeur) => {
  const found = (props.blacklist || []).find(user_id => user_id === chauffeur.value)
  if (found) {
    // chauffeur.disabled = true
    chauffeur.label = `👎 ${chauffeur.label || ''}`
    if (props.klant_id > 0) {
      chauffeur.link = `/opvolging/enquetes?user_id=${chauffeur.value}&klant_id=${props.klant_id}`
    }
  }
}

const mapChauffeurGeenCash = (chauffeur) => {
  if (props.isTeOntvangen && chauffeur.is_cash_toestaan === false) {
    // chauffeur.disabled = true
    chauffeur.label = `❌💵 ${chauffeur.label || ''}`
  }
}

provide('filterChauffeurs', (chauffeur) => {
  mapChauffeurAfwezigheden(chauffeur)
  mapChauffeurBlacklist(chauffeur)
  mapChauffeurGeenCash(chauffeur)
  return true
})

const onHandleChironReservatieSturen = (chauffeurIndex) => {
  const record = list.value[chauffeurIndex]

  const message = 'Chiron reservatie doorsturen?'
  const callback = () => api('CHIRON_RESERVATIE', {
    planning_id: record.planning_id,
    chauffeur_id: record.chauffeur_id,
  }).then(() => {
    list.value[chauffeurIndex].shift_chiron_status = 'RESERVATIE'
  })

  openFixedModal('BEVESTIG_ACTIE', { message, callback })
}

const onHandleCustomEdit = (chauffeurIndex) => {
  const record = list.value[chauffeurIndex]
  console.log({ record })
  debugger
  openFixedModal('FORM_TOEKENNING', {
    modelValue: {
      id: record.id,
      shift_id: record.shift_id,
      planning_id: record.planning_id,
      user_id: record.chauffeur_id,
      wagen_id: record.shift_wagen_id,
      start: record.shift_start,
      aangekomen: record.shift_aangekomen,
      klant_ingestapt: record.shift_klant_ingestapt,
      klant_afgezet: record.shift_klant_afgezet,
      einde: record.shift_einde,
      is_no_show: record.shift_is_no_show,
      wachttijd: record.shift_wachttijd,
      chiron_status: record.shift_chiron_status,
    },
    callback: (updated) => {
      list.value[chauffeurIndex].chauffeur_id = updated.user_id
      list.value[chauffeurIndex].shift_wagen_id = updated.wagen_id
      list.value[chauffeurIndex].shift_start = updated.start
      list.value[chauffeurIndex].shift_aangekomen = updated.aangekomen
      list.value[chauffeurIndex].shift_klant_ingestapt = updated.klant_ingestapt
      list.value[chauffeurIndex].shift_klant_afgezet = updated.klant_afgezet
      list.value[chauffeurIndex].shift_einde = updated.einde
      list.value[chauffeurIndex].shift_is_no_show = updated.is_no_show
      list.value[chauffeurIndex].shift_wachttijd = updated.wachttijd
    },
  })
}
</script>
