<template>
  <div class="w-full" style="z-index: 1;">

    <div class="card bg-white mb-1 lg:sticky lg:top-0 gap-0" style="width:inherit; z-index: 1;">
      <div class="flex flex-col lg:flex-row gap-4 items-start">
        <h2 class="whitespace-nowrap w-full h-auto">
          Kalender &mdash; {{ capitalize(dateWritten(date)) }}
        </h2>

        <div class="mt-1 text-center justify-end">
          <UIInputDatePicker buttons v-model:date="date" />
        </div>

        <div class="flex flex-col sm:flex-row lg:flex-col justify-center lg:justify-start items-end gap-2 w-full">
          <div class="inline-flex flex-row items-center gap-2">
            <button v-if="store.getters.hasAdminPermission" class="btn indigo small" @click="() => toggleTab(tabs.WERKDRUK)">
              <i class="fas fa-clock"></i>
            </button>
            <router-link v-if="store.getters.hasAdminPermission" target="_blank" :to="{ name: 'RittenRendabiliteit', query: { date } }" class="btn bg-teal-400 text-white">
              <i class="fas fa-money-check-alt"></i>
            </router-link>
            <router-link v-if="store.getters.hasAdminPermission" target="_blank" :to="{ name: 'RittenVRP', query: { date } }" class="btn bg-orange-400 text-white">
              <i class="fas fa-route text-base"></i>
            </router-link>
            <router-link v-if="store.getters.hasAdminPermission" target="_blank" :to="{ name: 'PrintContracten', query: { date: date } }" class="btn purple">
              <i class="fas fa-file-contract text-base"></i>
            </router-link>
            <button class="btn green small" @click="refreshAll">
              <i class="fas fa-sync"></i>
            </button>
            <button v-if="store.getters.hasAdminPermission" class="btn yellow small" @click="showGeocodeStatus = !showGeocodeStatus">
              <i class="fas fa-globe-americas"></i>
            </button>
            <UIButtonCompact v-if="store.getters.hasAdminPermission" v-model="compact" class="small" />
          </div>

          <UICheckbox
            class="whitespace-nowrap"
            :label="`(${geannuleerdeRitten?.length}) Annulaties tonen`"
            v-model="annulatiesTonen"
          />
        </div>
      </div>

      <div v-if="kalender?.list?.length" class="flex flex-col lg:flex-row -mt-5" style="width: calc(100% - 200px)">
        <UITableKalenderAfwezigheden :afwezigheden="afwezigheden?.list"/>
      </div>
    </div>

    <div class="card w-full" v-if="tab === tabs.WERKDRUK">
      <TableWerkdruk :kalender="bevestigdeRitten" />
    </div>

    <template v-else>
      <div class="card w-full mb-1 lg:pt-24" v-if="store.getters.hasAdminPermission">
        <div class="grid grid-cols-2 lg:grid-cols-4 gap-2 py-2">
          <UIFormElement label="Chauffeur">
            <SelectChauffeur nullable v-model="filters.user_id" :prefix-items="[{ label: 'Niet toegekend', value: -1 }]" />
          </UIFormElement>
          <UIFormElement label="Wagen">
            <SelectWagen nullable active v-model="filters.wagen_id" />
          </UIFormElement>
          <UISelectFixed multiple type="KALENDER_FILTERS" placeholder="Alles tonen" v-model="filters.options" />
          <UIFormElement label="Zoeken">
            <input type="text" placeholder="Zoeken..." v-model="filters.search" @input="filters.search = $event?.target?.value || ''" />
          </UIFormElement>
        </div>
      </div>

      <div class="card w-full mb-1 relative overflow-x-auto">
        <small v-if="loading" class="block text-center py-4">Dag {{ date }} aan het laden...</small>
        <TableKalender
          v-else
          :compact="compact"
          :show-geocode-status="showGeocodeStatus"
          :annulaties-tonen="annulatiesTonen"
          :reservaties="kalenderFiltered"
          :afspraken="afsprakenFiltered"
          @date="date = $event"
          @refresh-kalender="refreshKalender"
          @refresh-afspraken="refreshAfspraken"
        />
      </div>

      <div>
        <UIButtonNieuweRitToevoegen class="fixed bottom-5 right-5" :date="date" />
        <UIButtonNieuweAfspraakToevoegen class="fixed bottom-16 right-5" :date="date" />

        <small  v-if="store.getters.hasAdminPermission && !loading && !compact" class="text-xs font-normal flex flex-col mt-4">
          <h2 class="text-sm">{{ dateWritten(date) }}</h2>
          <span class="flex flex-row gap-2">
            <span class="whitespace-nowrap">Afspraken: {{ afspraken?.list?.length || 0 }}</span> &mdash;
            <span class="whitespace-nowrap">Ritten: {{ waardeRittenAantal }}</span>
          </span>
          <span class="flex flex-col gap-x-2">
            <span class="flex flex-col xl:flex-row gap-1 xl:items-center">
              <span class="whitespace-nowrap">Dagwaarde: {{ formatPrice(waardeDagTotaal + waardeAnnulatieKostenDagTotaal) }} EUR</span>
              <small class="whitespace-nowrap">(Annulatiekosten: {{ formatPrice(waardeAnnulatieKostenDagTotaal) }} EUR)</small>
            </span>
            <span class="whitespace-nowrap">Kilometers: {{ formatPrice(waardeKilometersTotaal) }}</span>
          </span>
        </small>
      </div>
    </template>

  </div>
</template>

<script setup>
import { ref, computed, provide } from 'vue'

import useGetApi from '@/hooks/useGetApi'
import useRouteQueryDate from '@/hooks/useRouteQueryDate'
import { capitalize, lowercase } from '@/functions/formatText'
import { formatPrice } from '@/functions/formatNumber'
import { dateWritten } from '@/functions/formatDate'

import TableKalender from '@/components/Table/Kalender/Index.vue'
import TableWerkdruk from '@/components/Table/Werkdruk/Index.vue'
import SelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import SelectWagen from '@/components/UI/Select/Wagen.vue'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UIFormElement from '@/components/UI/Form/Element.vue'
import UIButtonCompact from '@/components/UI/Button/Compact.vue'
import UIButtonNieuweRitToevoegen from '@/components/UI/Button/NieuweRitToevoegen.vue'
import UIButtonNieuweAfspraakToevoegen from '@/components/UI/Button/NieuweAfspraakToevoegen.vue'
import UICheckbox from '@/components/UI/Input/Checkbox.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UITableKalenderAfwezigheden from '@/components/Table/Kalender/Afwezigheden.vue'
import { useStore } from 'vuex'

const store = useStore()
const date = useRouteQueryDate('date')
const compact = ref(false)
const showGeocodeStatus = ref(false)
const annulatiesTonen = ref(false)
const filters = ref({
  user_id: null,
  wagen_id: null,
  is_factuur_nodig: null,
  options: [],
  search: '',
})

const tabs = {
  KALENDER: 'KALENDER',
  WERKDRUK: 'WERKDRUK',
}
const tab = ref(tabs.KALENDER)
const toggleTab = (newTab) => {
  tab.value = tab.value === newTab ? tabs.KALENDER : newTab
}

const apiData = computed(() => ({ all: true, date: date.value }))
const { data: afspraken, getData: refreshAfspraken } = useGetApi('AFSPRAAK_LIST', apiData, { watch: true })

const afsprakenFiltered = computed(() => {
  return (afspraken.value?.list || []).filter(item => {
    if (!item) return false
    if (filters.value.user_id == -1 && item.user_id > 0) {
      return false
    } else if (filters.value.user_id > 0 && item.user_id !== filters.value.user_id) {
      return false
    }

    for (const filterOptie of filters.value.options) {
      if (typeof filterOptie?.filterFn !== 'function') continue
      const shouldShow = filterOptie?.filterFn(item)
      if (!shouldShow) return false
    }

    if (filters.value.search) {
      const term = lowercase(filters.value.search).trim()
      return (
        lowercase(item.titel).includes(term)
        || lowercase(item.opmerking || '').includes(term)
        || lowercase((!item.adressen || typeof item.adressen === 'string' ? [] : item.adressen).map(el => el.adres || el).join(' ')).includes(term)
      )
    }
    return true
  })
})

// eslint-disable-next-line no-unused-vars
const { data: kalender, getData: refreshKalender, reset, cancel, loading } = useGetApi('/api/dashboard/kalender/list', apiData, { watch: true })

const kalenderFiltered = computed(() => {
  return (kalender.value?.list || []).map(item => {
    if (!item.chauffeurs?.length) {
      item.chauffeurs = [{ chauffeur_id: null, wagen_id: null, omzet: item.rit.calculated_omzet_chauffeur }]
    }
    return item
  }).filter(item => {
    if (!item) return false
    if (filters.value.user_id == -1 && !!item.chauffeurs.find(x => x.chauffeur_id > 0)) {
      return false
    } else if (filters.value.user_id > 0 && !item.chauffeurs.find(x => x.chauffeur_id === filters.value.user_id)) {
      return false
    }
    if (filters.value.wagen_id && !item.chauffeurs.find(x => x.wagen_id === filters.value.wagen_id)) {
      return false
    }

    for (const filterOptie of filters.value.options) {
      if (typeof filterOptie?.filterFn !== 'function') continue
      const shouldShow = filterOptie?.filterFn(item)
      if (!shouldShow) return false
    }

    if (filters.value.search) {
      const term = lowercase(filters.value.search).trim()
      return (
        lowercase(item.klant?.firstname).includes(term)
        || lowercase(item.klant?.lastname).includes(term)
        || lowercase(item.klant?.phone).includes(term)
        || lowercase(item.klant?.email).includes(term)
        || lowercase(item.reiziger?.firstname).includes(term)
        || lowercase(item.reiziger?.lastname).includes(term)
        || lowercase(item.reiziger?.phone).includes(term)
        || lowercase(item.reiziger?.email).includes(term)
        || lowercase(item.rit?.vlucht).includes(term)
        || lowercase(item.rit?.promotie_code).includes(term)
        || lowercase(item.adressen?.map(el => el.adres).join(' ')).includes(term)
      )
    }
    return true
  })
})

const apiDataAfwezigheden = computed(() => ({
  year: new Date(date.value).getFullYear(),
  month: new Date(date.value).getMonth() + 1,
  day: new Date(date.value).getDate(),
  status: 'ACCEPTED',
  roundDay: true,
}))
const { data: afwezigheden, getData: refreshAfwezigheden } = useGetApi('KALENDER_AFWEZIGHEID_LIST', apiDataAfwezigheden, { throttle: 5000, watch: true })
provide('afwezigheden', afwezigheden)

const refreshAll = () => {
  refreshAfwezigheden()
  refreshAfspraken()
  refreshKalender()
}

const bevestigdeRitten = computed(() => (kalender.value?.list || []).filter(el => el?.rit?.is_bevestigd))
const geannuleerdeRitten = computed(() => (kalender.value?.list || []).filter(el => el?.rit?.is_geannuleerd))
const waardeRittenAantal = computed(() => bevestigdeRitten.value.length)
const waardeDagTotaal = computed(() => bevestigdeRitten.value.reduce((total, next) => total + (next?.rit?.prijs || 0) / (next?.rit?.is_retour ? 2 : 1), 0))
const waardeAnnulatieKostenDagTotaal = computed(() => geannuleerdeRitten.value.reduce((total, next) => total + (next?.rit?.kosten_annulatie || 0) / (next?.rit?.kosten_annulatie ? 2 : 1), 0))
const waardeKilometersTotaal = computed(() => bevestigdeRitten.value.reduce((total, next) => total + next?.rit?.kilometers || 0, 0))

const formatLabelChauffeurs = (item, originalLabel) => {
  if (!item?.value) {
    return originalLabel
  }
  if (item.value === -1) {
    const aantalRittenNietToegekend = bevestigdeRitten.value.reduce((prev, next) => {
      if (!next?.chauffeurs?.length || !next?.chauffeurs?.find(c => c.chauffeur_id > 0)) {
        return prev + 1
      }
      return prev
    }, 0)
    return `(${aantalRittenNietToegekend}) ${originalLabel}`
  }
  const aantalRitten = bevestigdeRitten.value.reduce((prev, next) => {
    if (next?.chauffeurs?.find(c => c.chauffeur_id === item.value)) {
      return prev + 1
    }
    return prev
  }, 0)
  return `(${aantalRitten}) ${originalLabel}`
}

provide('formatLabelChauffeurs', formatLabelChauffeurs)
</script>
